.App {
  text-align: left;
}

.App p {
  text-align: left;
  padding-left: 10px;
  margin: 10px;
}
.App a {
  color: #ace6f3;
}

.slick-slide {
  width: 100%;
  padding-bottom: 1em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-logo2 {
  height: 10vmin;
  pointer-events: none;
}
h1 {
  text-align: center;
}
caption {
  padding-bottom:10px
}

.section2 {
  background-color: #61dafb;
  color: #000;
  padding: 20px;
  margin: 20px 20px 20px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.section2 img {
  float:right;
}

.section2 p {
  margin-bottom: 1em;
  padding: 0;
  font-size: 1em;
  font-weight: 300;
  align-self: flex-start;
  padding-bottom:10px;
}
caption {
  font-size: .5em;
}
.section3 h1 {
  color: white;
}
.section2 {
}
/* Other section styles (section3, section4, etc.) here... */

.App-header {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-header p, .App-header h1 {
text-align: center;
}
.footer {
  background-color: #000;
  text-align: center;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
  padding-top:2em;

}
.footer div {
  margin-bottom:30px;
}
.App-link {
  color: #ace6f3;
}

.App-banner {
  background-color: #081113;
  padding: 0px;
  min-height: 10vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
  padding-bottom: 5px;
}

.banner-container {
  position: relative; /* This allows absolute positioning within it */
}

.cr-logo {
  position: absolute;
  top: 10px; /* Adjust as needed for top-right corner */
  right: 10px; /* Adjust as needed for top-right corner */
  width: 30px; /* Adjust the size as needed */
  height: auto;
}
.bwfilebaby {
  max-width:200px;
}

.image-container {
  position: relative;
}

.info {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.cr-logo:hover + .info {
  display: block;
}
.banner-text {
  position: absolute;
  top: 50%; /* Adjust as needed */
  left: 50%; /* Adjust as needed */
  transform: translate(-50%, -50%); /* Center the text */
  color: white; /* Choose your text color */
  font-size: 1.5em; /* Adjust the font size as needed */
  text-align: center; /* Align the text */
  /* Add other styling as needed */
}
.cclogo {
  width: 200px;
}
.credentials {
  width:200px;
}
.note {
  font-size: .5em;
}
.check-list {
  list-style-type: none; /* Removes default bullets */
  padding: 0;
  margin-left:2em;
  margin-bottom: 2em;
}
.check-list li {
  padding-bottom: 2em;
}
@media (max-width: 768px) {
  .footer {
    text-align: center;
    padding: 20px 10px; /* Adjust padding for mobile */
  }

  .footer img {
    max-width: 100%; /* Ensures images are responsive and do not overflow */
    height: auto; /* Maintain aspect ratio */
  }

}
/*.check-list li:before {*/
/*  content: "✓ "; Adds check mark before each list item */
/*  color: green; Optional: Change the color of the check mark */
/*  padding-right: 5px; Optional: Adjust space between check mark and list item */
/*}*/

